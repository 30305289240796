<template>
  <div class="">
    <el-dialog
      :title="$t('consume.notice')"
      :visible.sync="dialogFormVisible"
      :before-close="handleClose"
      :width="$isMobile ? '90%' : '800px'"
    >
      <div class="content">
        <div>Thiếu Hiệp thân mến,</div>
        <div>
          Để đảm bảo quá trình giao dịch của Thiếu Hiệp được an toàn, tiện lợi
          và hơn nữa còn tiết kiệm hơn ưu đãi hơn so với giao dịch trực tiếp
          trong game, Thiếu Hiệp hãy đến trang nạp chính thức và duy nhất của
          NPH.
        </div>
        <div class="weight">Phương thức an toàn, ưu đãi:</div>
        <ul>
          <li>
            <span class="weight">An toàn, tiện lợi:</span>
            <span>
              Sau khi chọn nhân vật, chỉ cần nhấp để nạp đạo cụ tương ứng, đảm
              bảo sự tiện lợi và an toàn.
            </span>
          </li>
          <li>
            <span class="weight">Ưu đãi vượt trội:</span>
            <span>
              Nạp tại trang nạp có giá ưu đãi hơn so với việc trực tiếp nạp
              trong game.
            </span>
          </li>
        </ul>

        <div class="weight">Hướng dẫn sử dụng:</div>
        <ul>
          <li>
            <span class="weight">Đạo cụ: :</span>
            <span>
              Sau khi nạp thành công, Kim Kim Tương sẽ được gửi trực tiếp vào
              túi đồ của nhân vật mà Thiếu Hiệp đã chọn, có thể dùng để đổi các
              gói nạp hoặc gói quà trong game.
            </span>
          </li>
          <li>
            <span class="weight">Tỷ lệ quy đổi:</span>
            <span>
              1 Kim Kim Tương = 0,01 USD, có thể sử dụng tự do trong game.
            </span>
          </li>
          <li>
            <span class="weight">Khấu trừ tự động:</span>
            <span>
              Trong game, chỉ cần nhấp vào gói nạp hoặc gói quà, hệ thống sẽ tự
              động khấu trừ số lượng đạo cụ tương ứng với giá tiền.
            </span>
          </li>
        </ul>

        <div>
          Cảm ơn sự ủng hộ và tin tưởng của Thiếu Hiệp. Chúc Thiếu Hiệp chơi
          game thật vui vẻ!
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "",
  mixins: [],
  components: {},
  props: {},
  data() {
    return {
      dialogFormVisible: false,
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    handleClose() {
      this.dialogFormVisible = false;
    },
  },
};
</script>

<style scoped lang="scss">
/deep/.el-dialog {
  border-radius: 32px !important;
}
/deep/.el-dialog__wrapper {
  text-align: center;
  white-space: nowrap;
  overflow: auto;
  &:after {
    content: "";
    display: inline-block;
    vertical-align: middle;
    height: 100%;
  }
  .el-dialog {
    margin: 30px auto !important;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    white-space: normal;
  }
}
.content {
  text-align: left;
  font-size: 18px;
  line-height: 30px;
  color: black;
  overflow-wrap: break-word; /* 在必要时换行 */
  word-break: normal; /* 正常换行，不会拆分单词 */
  white-space: normal; /* 允许换行 */
}

.content ul {
  margin: 10px 0;
 
  width: 90%;
}
.weight {
  font-weight: 700;
}
@media screen and (max-width: 1000px) {
  /deep/.el-dialog {
    width: 90%;
  }
  .content {
    font-size: 15px;
    line-height: 25px;
    height: 400px;
    overflow-y: auto;
  }
  .content ul {
    width: 80%;
  }
}
</style>
