<template>
  <div style="height: 100%">
    <el-dialog
      :title="$t('consume.selectCoupon')"
      :visible.sync="dialogFormVisible"
      :before-close="handleClose"
      style="height: 80%; margin: auto"
      center
      :width="$isMobile ? '90%' : '40%'"
      v-if="productDetail?.data?.coupon"
    >
      <div class="coupon" v-if="productDetail.data.coupon.length > 0">
        <div
          class="_coupon_item"
          @click="couponCheck(item, index)"
          :class="{
            _coupon_check: checkNum === index,
            _coupon_item_disable:
              (!item.channel_type_arr.includes(couponName) &&
                item.title !== 'Coin') ||
              item.limit_time > 0 ||
              (item.usage_num_daily > 0 && item.usage_num_daily_count <= 0),
          }"
          v-for="(item, index) in productDetail.data.coupon"
          :key="item.coupon_id"
        >
          <div class="_coupon_box">
            <div class="_coupon_box_top">
              <div class="_coupon_center">
                <div class="_coupon_title">
                  <span>{{ item.title }}</span>
                  <span
                    class="coupon_usage_num"
                    v-if="item.usage_num > 1 && item.usage_num_daily === 0"
                  >
                    {{ $t("consume.remainingNum", { num: item.usage_num }) }}
                  </span>
                  <span
                    class="coupon_usage_num"
                    v-else-if="
                      item.usage_num_daily > 0 && item.usage_num_daily_count > 0
                    "
                  >
                    ({{
                      $t("consume.limitNum", {
                        num: item.usage_num_daily_count,
                      })
                    }})
                  </span>
                </div>
                <div class="_coupon_date">
                  <div class="_coupon_date_text">
                    {{
                      item.coupon_date_text
                        ? $t("consume.validityPeriod")
                        : $t("consume.availableDates")
                    }}
                  </div>
                  <div v-html="item.date_text"></div>
                </div>
                <div class="_coupon_box_footer">
                  <div
                    class="_coupon_box_bottom"
                    :class="{
                      _coupon_box_bottom_active: item.channelShow,
                    }"
                  >
                    <div class="_coupon_box_timing" v-if="item.limit_time > 0">
                      {{ $t("consume.use") }}
                      <span :class="'_coupon_box_timing_val' + index">
                        {{ getTimeS(item.limit_time) }}
                      </span>
                    </div>
                  </div>
                  <div class="_coupon_coin">
                    <!-- {{ item.give_coin_text }}
                    {{ item.type }} -->
                    {{
                      $t(`consume.deliver${item.type}`, {
                        money: item.order_coin,
                        gift: item.type === "2" ? item.rebate : item.give_coin,
                      })
                    }}
                  </div>
                </div>
                <div @click.stop="couponPayTypeShow(item)" class="_coupon_sjx">
                  <!-- <img src="../assets/img/xem.png" /> -->
                  <i
                    class="el-icon-info"
                    style="color: #5cb6ff; font-size: 30px"
                  ></i>
                </div>
              </div>
              <div class="_coupon_right">
                <img
                  class="_coupon_check_img"
                  src="../assets/img/check.png"
                  alt=""
                />
              </div>
              <div
                @click="handleExplain(item)"
                class="_coupon_item_explain"
                :style="{
                  display:
                    (!item.channel_type_arr.includes(couponName) &&
                      item.title !== 'Coin') ||
                    +item.limit_time > 0 ||
                    (item.usage_num_daily > 0 &&
                      item.usage_num_daily_count <= 0)
                      ? 'block'
                      : 'none',
                }"
              ></div>
            </div>
          </div>
        </div>
        <!-- <div class="add_coupon" @click="openGiftCodeDialog"></div> -->
      </div>
      <div v-else>{{ $t("consume.notUseCoupon") }}</div>
      <el-button
        type="primary"
        style="
          width: 100%;
          background: rgba(17, 136, 255);
          border-color: transparent;
          margin-top: 20px;
        "
        @click="confirm()"
        >{{ $t("Login.confirm") }}</el-button
      >
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "",
  mixins: [],
  props: {},
  data() {
    return {
      dialogFormVisible: false,
      productDetail: [],
      checkNum: null, //优惠券被选中
      checkNum2: null, //优惠券被选中
      checkIndex: null, //优惠券索引值
      couponName: "", //选中的支付方式名字
      coupon_info: {}, //点击的优惠券信息
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    confirm() {
      if (this.checkNum !== null) {
        const { reduce_amt, give_coin, title, discount_amount, type, rebate } =
          this.coupon_info;
        this.$parent.goodsInfo.coupon_reduce_amt = reduce_amt;
        this.$parent.goodsInfo.coupon_give_coin = give_coin;
        this.$parent.goodsInfo.coupon_title = title;
        this.$parent.goodsInfo.coupon_type = type;
        this.$parent.goodsInfo.coupon_discount_amount = discount_amount;
        // this.$parent.goodsInfo.extra_coin_num = discount_amount
        //   ? (this.$parent.goodsInfo.extra_coin / 100) * discount_amount
        //   : this.$parent.goodsInfo.extra_coin_num;
        this.$parent.goodsInfo.extra_coin_num =
          this.$parent.spareGoodsInfo.extra_coin_num;
        this.$parent.goodsInfo.order_amt =
          this.$parent.spareGoodsInfo.order_amt;
        if (type === "2") {
          this.$parent.goodsInfo.extra_coin_num = Math.floor(
            this.$parent.goodsInfo.extra_coin_num -
              (this.$parent.goodsInfo.extra_coin_num * rebate) / 100
          );
          this.$parent.goodsInfo.order_amt =
            this.$parent.goodsInfo.order_amt -
            Math.floor((this.$parent.goodsInfo.order_amt * rebate) / 100);
        }
        this.$parent.goodsInfo.total_give =
          Number(this.$parent.goodsInfo.give_coin) +
          Number(this.$parent.goodsInfo.extra_coin_num) +
          Number(this.$parent.goodsInfo.coupon_give_coin);

        this.$parent.goodsInfo.checkNum = JSON.parse(
          JSON.stringify(this.checkNum)
        );
        this.$parent.goodsInfo.coupon_info = JSON.parse(
          JSON.stringify(this.coupon_info)
        );
      } else {
        this.$parent.goodsInfo = JSON.parse(
          JSON.stringify(this.$parent.spareGoodsInfo)
        );
      }
      this.dialogFormVisible = false;
    },
    handleClose() {
      this.coupon_info = this.$parent.goodsInfo.coupon_info;
      this.checkNum = this.$parent.goodsInfo.checkNum;
      this.productDetail.data.coupon.forEach((item, index) => {
        if (this.checkNum !== null && index == this.checkNum) {
          item.check = true;
        } else {
          item.check = false;
        }
      });
      this.$forceUpdate();
      this.dialogFormVisible = false;
    },
    handleExplain() {
      this.$message.warning(this.$t("consume.reselect"));
    },
    couponPayTypeShow(item) {
      this.$alert(
        `<div class="_coupon_inf"> <p> ${this.$t("consume.channel")} <span>${
          item.pay_type_text
        }</span></p> </div>`,
        {
          dangerouslyUseHTMLString: true,
          confirmButtonText: this.$t("Info.cancel"),
        }
      );
    },
    couponCheck(item, index) {
      //判断是否是可选的不可选的就return出去
      if (
        (!item.channel_type_arr.includes(this.couponName) &&
          item.title !== "Coin") ||
        item.limit_time > 0 ||
        (item.usage_num_daily > 0 && item.usage_num_daily_count <= 0)
      ) {
        return;
      }
      this.productDetail.data.coupon.forEach((i, ind) => {
        if (ind !== index) {
          i.check = false;
        }
      });
      //判断是否领取优惠券
      item.check = !item.check;
      this.checkNum = this.productDetail.data.coupon[index].check
        ? index
        : null;
      this.$forceUpdate();
      this.coupon_info = item;
      if (item.check && item.is_get === "0") {
        let params = {
          coupon_id: item.coupon_id,
        };
        this.$http.get("/consume/getCoupon", { params }).then((res) => {
          if (res.data.code === 200) {
            item.is_get = "1";
            item.user_coupon_id = res.data.data.user_coupon_id;
            item.coupon_date_text = 1;
            item.date_text = `<div style="font-size: 12px;color: #83B8E7;">${res.data.data.valid_start_time}—${res.data.data.valid_end_time}</div>`;
            //如果选中优惠券就赋值，否则为空
            this.$parent.payData.user_coupon_id = res.data.data.user_coupon_id;
            this.$forceUpdate();
          } else {
            this.$message.error(res.data.msg);
          }
        });
      } else {
        this.$parent.payData.user_coupon_id = item.check
          ? item.user_coupon_id
          : "";
      }
    },
    _couponStartTiming(ts, key, item) {
      let start = setInterval(function () {
        ts = ts > 0 ? ts - 1 : 0;
        item.limit_time = ts;
        // this.getTimeS(ts);
        if (ts <= 0) {
          clearInterval(start);
        }
      }, 1000);
    },
    // 倒计时时间格式处理
    getTimeS(ts) {
      let hh = parseInt((ts / 60 / 60) % 24, 10);
      let mm = parseInt((ts / 60) % 60, 10);
      let ss = parseInt(ts % 60, 10);
      return `${hh < 10 ? "0" + hh : hh}:${mm < 10 ? "0" + mm : mm}:${
        ss < 10 ? "0" + ss : ss
      }`;
    },
  },
};
</script>

<style scoped lang="scss">
/deep/.el-dialog__wrapper {
  height: 100% !important;
}
/deep/.el-dialog {
  border-radius: 32px !important;
  margin-top: 8vh !important;
}

//优惠券
.coupon {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-height: 350px;
  overflow: auto;
  .add_coupon {
    width: 45%;
    height: 150px;
    position: relative;
    display: flex;
    overflow: hidden;
    cursor: pointer;
    margin: 6.4px 10px;
    background: url(../assets/img/addCoupon.png) no-repeat;
    background-size: 100% 100%;
  }

  ._coupon_item_explain {
    position: absolute;
    top: calc(30%);
    right: 0;
    width: 35px;
    height: 35px;
    cursor: pointer;
    background: url(../assets/img/explain.png?2) no-repeat center center;
    background-size: 30px 30px;
  }
}

._coupon_item {
  width: 45%;
  position: relative;
  display: flex;
  overflow: hidden;
  cursor: pointer;
  margin: 6.4px 10px;
  background: url(../assets/img/useCoupon.png) no-repeat;
  background-size: 100% 100%;
  -moz-user-select: none;
  -khtml-user-select: none;
  user-select: none;
}

._coupon_item_disable {
  filter: grayscale(100%);
}

._coupon_left {
  display: flex;
  align-items: center;
  width: 32px;
  overflow: hidden;
  background-image: linear-gradient(to right bottom, #57ebfe, #1188ff);
}

._coupon_left_arrow {
  margin-left: -16px;
  width: 32px;
  height: 32px;
  box-shadow: 0 0 2px #ddd inset;
  background-color: #fff;
  border-radius: 50%;
}

._coupon_box {
  flex: 1;
}

._coupon_box_top {
  display: flex;
  align-items: center;
  position: relative;
}

.coupon_usage_num {
  font-weight: normal;
  font-size: 16px;
  color: #ff2b11;
}

._coupon_line {
  width: 100%;
  height: 1px;
  background: linear-gradient(
    to right,
    #ddd,
    #ddd 5px,
    transparent 5px,
    transparent
  );
  background-size: 8px 100%;
}

._coupon_box_footer {
  height: 40px;
  margin-top: 20px;
  margin-left: 3px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

._coupon_box_bottom {
  display: flex;
  flex-direction: column;
}

._coupon_box_timing {
  line-height: 15px;
  color: #ff2b11;
  font-size: 14px;
}

._coupon_sjx {
  width: 30px;
  cursor: pointer;
  position: absolute;
  right: 18px;
  bottom: 10px;
}

._coupon_sjx img {
  width: 55px;
  transition: 0.4s;
}

.el-message-box ._coupon_inf {
  width: 100%;
  height: 0;
  padding: 0 8px;
  line-height: 14px;
  font-size: 14px;
  box-sizing: border-box;
  transition: 0.3s;
}

._coupon_box_bottom_active ._coupon_sjx img {
  transform: rotateZ(180deg);
  transition: 0.4s;
}

._coupon_box_bottom_active ._coupon_inf {
  transition: 0.3s;
  height: auto;
}

._coupon_inf span {
  color: #1188ff;
}

._coupon_center {
  flex: 1;
  padding: 10px 8px;
  min-height: 24px;
  box-sizing: border-box;
}

._coupon_title {
  min-height: 44px;
  font-size: 16px;
  font-weight: bold;
  position: relative;

  span {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

._coupon_date {
  font-size: 16px;
  font-weight: bold;
  color: #518ec5;
  height: 37px;
}

._coupon_coin {
  margin-top: 3.2px;
  font-size: 14px;
  color: #ff2b11;
}

._coupon_right {
  display: flex;
  align-items: center;
  width: 35px;
}

._coupon_check_img {
  display: none;
  width: 30px;
  height: 30px;
}

._coupon_check ._coupon_check_img {
  display: block !important;
}

@media screen and (max-width: 1024px) {
  /deep/.el-dialog {
    margin-top: 4vh !important;
  }
  /deep/.el-dialog--center .el-dialog__body {
    padding: 25px 10px 30px;
  }

  ._coupon_item {
    margin: 6.4px 0px;
  }

  ._coupon_date {
    height: 53px;
  }

  ._coupon_item,
  .add_coupon {
    width: 100%;
  }

  .add_coupon {
    margin: 0;
  }

  ._coupon_center {
    padding: 0px 8px;
  }
  ._coupon_date {
    font-size: 12px;
  }
  ._coupon_box_footer {
    margin-top: 0px;
  }

  ._coupon_title {
    font-size: 14px;

    span:nth-child(1) {
      padding-top: 5px;
    }
  }
}
@media screen and (max-width: 376px) {
  .shop_list {
    height: 200px !important;
  }
  .shop_list_item_text {
    font-size: 10px;
  }
  .coupon {
    max-height: 300px;
    overflow: auto;
  }
}
</style>

