<template>
  <div class="">
    <el-drawer
      :title="data.locale_name"
      :visible.sync="drawer"
      :direction="direction"
      :before-close="handleClose"
      :modal="true"
      :size="size"
    >
    <template v-slot:title>
      <span>{{data.locale_name}}</span>
      <span style="float: right; cursor: pointer;" @click="drawer = false">{{ $t('Landing.close') }}</span>
    </template>
      <div class="product_info">
        <!-- <div class="product_close">
          <span @click="() => (drawer = false)">{{ $t('Landing.close') }}</span>
        </div> -->
        <div v-html="data.locale"></div>
      </div>
      <el-button type="primary" class="product_btn" @click="handleClick(data)">
        购买</el-button
      >
    </el-drawer>
  </div>
</template>

<script>
export default {
  name: "",
  mixins: [],
  components: {},
  props: {},
  data() {
    return {
      drawer: false,
      direction: "btt",
      data: {},
      // size:'20%'
    };
  },
  computed: {
    size() {
      return this.$isMobile ? "50%" : "20%";
    },
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {
    handleClose(done) {
      done();
    },
    handleClick(item) {
      this.$parent.choosePay(item);
      this.drawer = false;
    },
  },
};
</script>

<style scoped lang="scss">
/deep/ .el-drawer__header {
  width: 38%;
  margin: 0 auto 32px;
  padding: 20px 0px 0;
}
/deep/.el-drawer__close-btn {
  display: none;
}
// /deep/.el-drawer__header span {
//   text-align: left;
// }
/deep/ .el-drawer__body {
  width: 50%;
  margin: 0 auto;
}

.product_info {
  height: 60%;
  width: 75%;
  word-wrap: break-word;
  margin: 0 auto;
}
.product_close span {
  cursor: pointer;
}
.product_btn {
  width: 75%;
  background: rgb(17, 136, 255);
  border-color: transparent;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

@media screen and (max-width: 1024px) {
  /deep/ .el-drawer__header {
  width: 75%;
  margin: 0 auto 32px;
  padding: 20px 20px 0;
}
  /deep/ .el-drawer__body {
    width: 100%;
    margin: 0 auto;
  }
  .product_info {
    height: 70%;
    word-wrap: break-word;
  }
  .product_btn {
    width: 80%;
  }
}
</style>
