<template>
  <div>
    <div id="StepIndicator" class="stepIndicator" v-if="showServe">
      <el-select
        v-model="value"
        :popper-append-to-body="false"
        :no-data-text="$t('consume.noData')"
        class="stepIndicator__select"
      >
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
          :disabled="item.disabled"
          @click.native="scrollTo(item)"
        >
        </el-option>
      </el-select>
    </div>
    <div style="margin-top: 0px">
      <div class="container">
        <div class="banner">
          <div class="banner-content banner-img">
            <div class="banner-content__center">
              <img
                :src="
                  $isMobile
                    ? gameData.background_small_img
                    : gameData.background_img
                "
                alt=""
              />
            </div>
          </div>
          <img
            :src="gameData.game_icon"
            alt="logo"
            class="banner-logo"
            style="display: none"
          /><span class="banner-title" style="display: none">{{
            gameData.game_name
          }}</span>
        </div>
        <div class="content content_1" ref="info" id="info">
          <div class="content-box">
            <div class="content-box__header">
              <div>
                <span class="content-box__header-number">1</span>
                <span class="content-box__header-content">
                  {{ $t("consume.gameId") }}
                </span>
              </div>
            </div>
            <div class="content-box__content">
              <div class="loginForm" v-if="!showServe">
                <el-form
                  :model="ruleForm"
                  :rules="rules"
                  ref="ruleForm"
                  label-width="100px"
                  class="demo-ruleForm"
                >
                  <!-- <el-form-item label="">
                    <el-select
                      style="width: 100%"
                      :popper-append-to-body="false"
                      :no-data-text="$t('consume.noData')"
                      :no-match-text="$t('consume.noData')"
                      v-model="form.country"
                      filterable
                      :placeholder="$t('consume.chooseCountry')"
                    >
                      <el-option
                        :label="item.label"
                        :value="item.value"
                        v-for="item in country"
                        :key="item.value"
                      ></el-option>
                    </el-select>
                  </el-form-item> -->
                  <el-form-item label="">
                    <el-select
                      style="width: 100%"
                      :popper-append-to-body="false"
                      :no-data-text="$t('consume.noData')"
                      :no-match-text="$t('consume.noData')"
                      v-model="form.servers"
                      @change="handleChange"
                      filterable
                      :placeholder="$t('consume.chooseServer')"
                    >
                      <el-option
                        :label="item.value"
                        :value="item.id"
                        v-for="item in servers_roles.servers"
                        :key="item.id"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="">
                    <el-select
                      style="width: 100%"
                      :popper-append-to-body="false"
                      v-model="form.roles"
                      :no-data-text="$t('consume.noData')"
                      :no-match-text="$t('consume.noData')"
                      :disabled="!form.servers"
                      filterable
                      :placeholder="$t('consume.chooseRole')"
                    >
                      <el-option
                        :label="item.value"
                        :value="item.id"
                        v-for="item in servers_roles.roles"
                        :key="item.id"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item>
                    <el-button
                      type="primary"
                      style="
                        width: 100%;
                        background: rgb(17, 136, 255);
                        border-color: transparent;
                      "
                      :style="{
                        background:
                          form.servers && form.roles
                            ? 'rgb(17,136,255)'
                            : '#ebebeb',
                      }"
                      :disabled="!form.servers || !form.roles"
                      @click="submitRole()"
                      >{{ $t("Login.confirm") }}</el-button
                    >
                  </el-form-item>
                </el-form>
              </div>
              <div v-else>
                <div
                  class="el-row"
                  style="margin-left: -5px; margin-right: -5px"
                >
                  <div
                    class="el-col el-col-24"
                    style="padding-left: 5px; padding-right: 5px"
                  >
                    <div class="custom-userInfo">
                      <p>
                        <span style="min-width: 67.0312px">
                          {{ $t("consume.Server") }}
                        </span>
                        <code style="display: inline-block">
                          {{ showRolesInfo.serversName }}
                        </code>
                      </p>
                      <p>
                        <span style="min-width: 85.2415px">
                          {{ $t("consume.roleId") }}
                        </span>
                        <code style="display: inline-block">
                          {{ showRolesInfo.id }}
                        </code>
                      </p>
                      <p>
                        <span style="min-width: 72.7557px">
                          {{ $t("consume.roleName") }}
                        </span>
                        <code style="display: inline-block">
                          {{ showRolesInfo.roleName }}
                        </code>
                      </p>
                      <!-- <p>
                        <span style="min-width: 72.7557px">
                          country:
                        </span>
                        <code style="display: inline-block">
                          {{ showRolesInfo.country }}
                        </code>
                      </p> -->
                    </div>
                  </div>
                </div>
                <div class="errorWrapper">
                  <button
                    type="button"
                    class="el-button btnLogout el-button--text"
                    @click="removeServe()"
                  >
                    {{ $t("consume.chooseOtherRole") }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 选择商品 -->
        <div class="content" v-if="showServe" ref="goods" id="goods">
          <div class="content-box">
            <div class="content-box__header">
              <div>
                <span class="content-box__header-number">2</span>
                <span class="content-box__header-content">
                  {{ $t("consume.ChooseCombo") }}
                </span>
              </div>
              <div>
                <!-- <div
                  class="filter-btn"
                  v-for="(item, index) in filterBtn"
                  :key="index"
                  :class="{ selected: selectedButton === index }"
                  @click="filterCheck(item, index)"
                >
                  <span>{{ item }}</span>
                </div> -->
                <div
                  class="rule"
                  v-if="[6, 7].includes(this.gameData.game_id)"
                  @click="handleNotice()"
                >
                  {{ $t("consume.notice") }}
                </div>
              </div>
            </div>
            <!-- <el-radio-group
                v-model="chooseProduct"
                @change="chooseProductType"
              >
                <el-radio-button label="1">Xu Game</el-radio-button>
                <el-radio-button label="2">Đặc biệt</el-radio-button>
              </el-radio-group> -->
            <div class="content-box__content">
              <div id="product_list">
                <div
                  class="list-item-bg"
                  v-for="item in productInfo"
                  :key="item.id"
                  @click.stop="choosePay(item)"
                >
                  <div class="list-item">
                    <div class="list-item-img">
                      <img :src="item.product_img" />
                      <!-- <div class="show-shopping"  @click.stop="choosePay(item)">
                        <div><i class="el-icon-shopping-cart-full"></i></div>
                        <div class="show-shopping-buy"></div>
                      </div>
                      <div class="show-shopping show-shopping-info" @click="openPreviewDialog(item)">
                        <div><i class="el-icon-info"></i></div>
                        <div class="show-shopping-buy"></div>
                      </div> -->
                      <img
                        src="@/assets/free.png"
                        class="free_chance"
                        v-if="item.free_chance > 0"
                      />
                    </div>
                    <!-- <div class="list-item-box">
                      <div class="list-item-rebate">
                       {{ item.need_pay }}
                        {{ item.price_code }}
                      </div>
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 选择支付方式 -->
        <div class="content" v-if="showPay" ref="pay" id="pay">
          <div class="content-box">
            <div class="content-box__header">
              <div>
                <span class="content-box__header-number">3</span>
                <span class="content-box__header-content">
                  {{ $t("consume.choosePayMethod") }}
                </span>
              </div>
            </div>
            <div class="payRegion">
              <div class="payRegionText">{{ $t("consume.payCountry") }}</div>
              <el-select
                style="width: 100%"
                :popper-append-to-body="false"
                :no-data-text="$t('consume.noData')"
                :no-match-text="$t('consume.noData')"
                v-model="payCountry"
                filterable
                :placeholder="$t('consume.chooseCountry')"
                @change="changePayCountry"
              >
                <el-option
                  :label="item.label"
                  :value="item.value"
                  v-for="item in newCountry"
                  :key="item.value"
                ></el-option>
              </el-select>
            </div>
            <el-checkbox-group style="margin-left: 5px" :value="checkList">
              <div class="content-box__content">
                <el-collapse
                  v-model="activeNames"
                  @change="handleCheck"
                  accordion
                >
                  <el-collapse-item
                    :title="item.title"
                    :name="item.title"
                    v-for="(item, index) in productDetail.data.newAtm"
                    :key="index"
                  >
                    <template slot="title">
                      <div class="pmt-group">
                        <div class="pmt-group__name">
                          <div class="displayName">
                            <p>
                              {{ item.title }}
                              <span v-if="item.title === 'Coin'">
                                ({{ $t("consume.remaining") }} :
                                {{
                                  Number($store.state.gameData.coin) +
                                  Number($store.state.gameData.bind_coin)
                                }})
                              </span>
                              <span v-if="item.title === 'MCoin'">
                                ({{ $t("consume.remaining") }} :
                                {{ Number($store.state.gameData.m_coin) }})
                              </span>
                              <span v-if="item.hot === 1" style="color: red">
                                <img
                                  style="width: 20px"
                                  src="@/assets/fire.png"
                                  alt=""
                                />
                              </span>
                            </p>
                            <!-- <p class="minAmount">{{ item.need_pay }} VND</p> -->
                            <p class="minAmount">
                              {{ item.showMoney || item.need_pay }}
                              {{ item.showMoney ? "" : "Coin" }}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div @click.stop>
                        <el-checkbox
                          class="check_box"
                          :label="item.title"
                          @change="handleCheck(item.title)"
                          :disabled="item.atm[0].disabled"
                          >&nbsp;</el-checkbox
                        >
                      </div>
                    </template>
                    <div
                      class="el-row"
                      style="margin-left: -5px; margin-right: -5px"
                    >
                      <div
                        class="el-col el-col-8"
                        :class="{ 'el-col-24': $isMobile }"
                        style="padding-left: 5px; padding-right: 5px"
                        @click="chooseCoupon(i, ind, item)"
                        v-for="(i, ind) in item.atm"
                        :key="ind"
                      >
                        <div
                          class="pmt-method"
                          :class="{
                            selected:
                              couponActive === ind && i.name === couponName,
                          }"
                        >
                          <span class="pmt-method__info">
                            <span class="pmt__cost">
                              <span v-if="i.name === 'Coin'">
                                {{
                                  Number($store.state.gameData.coin) +
                                  Number($store.state.gameData.bind_coin)
                                }}
                              </span>
                              <span v-else-if="i.name === 'MCoin'">
                                {{ Number($store.state.gameData.m_coin) }}
                              </span>
                              <span v-else>
                                {{ $t("consume.extraGift") }}
                                {{ i.give_coin || "0" }}
                              </span>
                            </span>
                          </span>
                          <div class="pmt-method__name">
                            <span>{{ i.name }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </el-collapse-item>
                </el-collapse>
                <el-button
                  type="primary"
                  style="
                    width: 100%;
                    background: rgba(17, 136, 255);
                    border-color: transparent;
                    margin-top: 10px;
                  "
                  v-if="this.couponName !== null && this.couponActive !== null"
                  @click="chooseCouponNext()"
                >
                  {{ $t("consume.next") }}</el-button
                >
              </div>
            </el-checkbox-group>
          </div>
        </div>
        <!-- 商品信息 -->
        <div
          class="content"
          v-if="showGoodsInfo"
          ref="goodsInfo"
          id="goodsInfo"
        >
          <div class="content-box">
            <div class="content-box__header">
              <div>
                <span class="content-box__header-number">4</span>
                <span class="content-box__header-content">
                  {{ $t("consume.confirmInfo") }}
                </span>
              </div>
            </div>
            <div class="content-box__content">
              <!-- <h2 class="list-title">{{ goodsInfo.product_info }}</h2> -->
              <div class="list-info">
                <div class="package-item">
                  <img :src="goodsInfo.product_img" alt="" />
                </div>
                <div class="info-detail">
                  <p>
                    <span> {{ $t("consume.Server") }}</span>
                    <code class="serverName">
                      {{ showRolesInfo.serversName }}
                    </code>
                  </p>
                  <p>
                    <span>{{ $t("consume.roleId") }}</span>
                    <code class="roleId">
                      {{ showRolesInfo.id }}
                    </code>
                  </p>
                  <p>
                    <span>{{ $t("consume.roleName") }}</span>
                    <code class="roleName">{{ showRolesInfo.roleName }}</code>
                  </p>
                  <p>
                    <span>{{ $t("consume.pay") }}</span>
                    <code class="pmtMethod">{{ goodsInfo.name }}</code>
                  </p>
                  <p
                    v-if="
                      goodsInfo.name !== 'Coin' && goodsInfo.name !== 'MCoin'
                    "
                  >
                    <span>{{ $t("consume.selectCoupon") }}</span>
                    <code
                      class="pmtMethod choose_coupon"
                      @click="openCouponDialog"
                    >
                      <span class="coupon_title" v-if="goodsInfo.coupon_title">
                        {{ goodsInfo.coupon_title }}
                      </span>
                      <span v-else-if="useCouponNum > 0">
                        {{ $t("consume.canUseCoupon", { num: useCouponNum }) }}
                      </span>
                      <span v-else style="color: grey"
                        >{{ $t("consume.notUse") }}
                      </span>
                      <i class="el-icon-arrow-right"></i>
                    </code>
                  </p>
                  <p>
                    <span>{{ $t("consume.price") }}</span>
                    <code class="pmtMethod">
                      <span v-if="goodsInfo.name === 'Coin'">
                        {{ Number(goodsInfo.order_amt_platform) }} Coin</span
                      >
                      <span v-else-if="goodsInfo.name === 'MCoin'">
                        {{ Number(goodsInfo.order_amt_MCoin) }} Coin
                      </span>
                      <span
                        v-else-if="goodsInfo.coupon_type === '3'"
                        class="description_reduce"
                      >
                        <span>{{ Number(goodsInfo.order_amt) }}</span>
                        {{ Number(goodsInfo.coupon_discount_amount)
                        }}{{ goodsInfo.price_code_country }}
                        <div class="more">
                          ({{ $t("consume.extraGift") }}
                          <a @click="openMoreDialog(goodsInfo)">
                            {{ Number(goodsInfo.total_give) }}</a
                          >
                          <!-- <div class="_coupon_item_explain"></div> -->
                          )
                        </div>
                      </span>
                      <span v-else class="description_reduce">
                        {{ Number(goodsInfo.order_amt)
                        }}{{ goodsInfo.price_code_country }}
                        <div class="more">
                          ({{ $t("consume.extraGift") }}
                          <a @click="openMoreDialog(goodsInfo)">
                            {{ Number(goodsInfo.total_give) }} </a
                          >)
                          <!-- <div class="_coupon_item_explain"></div> -->
                        </div>
                      </span>
                    </code>
                  </p>
                </div>
              </div>
              <!-- <p class="confirmPolicy" v-if="goodsInfo.product_info">
                Chi tiết sản phẩm ：{{ goodsInfo.product_info }}
              </p> -->
              <p class="confirmPolicy">
                {{ $t("consume.btnRemark") }}
              </p>
              <!-- <p class="confirmPolicy" style="color: red;">
                {{ $t("consume.napRemark") }}
              </p> -->
              <div
                class="free_chance_color"
                v-if="goodsInfo.rebate === 1 && free_chance > 0"
              >
                {{ $t("consume.limitedTimeEvent", { num: free_chance }) }}
              </div>
              <el-button
                type="primary"
                style="
                  width: 100%;
                  background: rgba(17, 136, 255);
                  border-color: transparent;
                "
                @click="handlePay()"
              >
                {{ $t("Login.confirm") }}</el-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <Loading1 :show="showLoading" :backgroundColor="false" />
    <GiftCode ref="giftCode" />
    <More ref="more" />
    <Coupon ref="showCoupon" />
    <Gate ref="Gate" />
    <Preview ref="preview" />
    <Notice ref="notice" />
  </div>
</template>

<script>
import { debounce } from "lodash";
import Loading1 from "../../components/Loading1";
import GiftCode from "../../components/giftCode.vue";
import More from "../../components/more";
import Coupon from "../../components/coupon.vue";
import Gate from "../../components/Gate";
import Preview from "../../components/preview.vue";
import Notice from "../../components/Notice.vue";
// import showMessage from "../../utils/showMessage";
export default {
  name: "",
  mixins: [],
  components: {
    Loading1,
    GiftCode,
    More,
    Coupon,
    Gate,
    Preview,
    Notice,
  },
  props: {},
  data() {
    return {
      country: [
        {
          value: "VN",
          label: "Việt Nam",
        },
        {
          value: "TH",
          label: "ประเทศไทย",
        },
        {
          value: "ID",
          label: "Indonesia",
        },
        {
          value: "PH",
          label: "Philippines",
        },
      ],
      newCountry: [],
      checkList: [],
      imgTitle: {
        CoinPay: 0,
        MOMO: 1,
        BankQR: 2,
        ATM: 3,
        VISA: 4,
      },
      payData: {
        cp_sid: "", // 区服id
        cp_roleid: "", // 角色id
        product_id: "", // 商品id
        pay_type: "", // 支付类型
        channel: "", // 支付渠道
        user_coupon_id: "", //优惠券id
      },
      // filterBtn: [this.$t("consume.currency"), this.$t("consume.special")], //切换商品按钮
      filterBtn: [], //切换商品按钮
      selectedButton: 0, //判断选中的按钮索引
      selectServe: false, //选择区服
      servers_roles: {
        servers: [],
        roles: [],
      }, //区服角色
      showRolesInfo: {}, //页面展示角色
      showServe: false, //展示区服
      showLoading: false, //loading状态
      showPay: false, //展示支付方式
      showCoupon: false, //展示优惠券
      channelShow: false, //优惠券使用详情下拉
      checkNum: null, //优惠券被选中
      couponActive: null, //支付方式被选中
      showGoodsInfo: false, //展示商品信息
      chooseProduct: "1", //选择商品
      activeNames: [], //默认下拉
      couponName: "", //选中的支付方式名字
      ruleForm: {
        name: "",
        password: "",
        type: "1",
      },
      payCountry: "VN",
      payCountry2: "VN",
      form: {
        servers: "",
        roles: "",
        // country: "",
      },
      // rules: {
      //   name: [
      //     {
      //       required: true,
      //       message: this.$t("consume.enterAccount"),
      //       trigger: "blur",
      //     },
      //   ],
      //   password: [
      //     {
      //       required: true,
      //       message: this.$t("consume.enterPassword"),
      //       trigger: "blur",
      //     },
      //   ],
      // },
      // options: [
      //   {
      //     value: "1",
      //     label: this.$t("consume.step1"),
      //     name: "info",
      //     disabled: false,
      //   },
      //   {
      //     value: "2",
      //     label: this.$t("consume.step2"),
      //     name: "goods",
      //     disabled: false,
      //   },
      //   {
      //     value: "3",
      //     label: this.$t("consume.step3"),
      //     name: "pay",
      //     disabled: true,
      //   },
      //   {
      //     value: "4",
      //     label: this.$t("consume.step4"),
      //     name: "goodsInfo",
      //     disabled: true,
      //   },
      // ],
      value: "1", //下拉选项
      productInfoData: [], //商品
      productInfo: [], //商品
      productInfo1: [], //商品1
      productInfo2: [], //商品2
      productDetail: { data: { newATM: [] } },
      productDetailNewAtm: {},
      free_chance: 0, //免单次数
      useCouponNum: 0, //可用x张优惠券
      goodsInfo: {
        name: "", //支付方式名称
        order_amt: "", //价格
        coupon_discount_amount: "", //优惠券满减
        coupon_reduce_amt: "", //扣完优惠券后的价格
        order_amt_platform: "", //coin价格
        total_give: "", //所有优惠加起来的数目
        give_coin: "", //支付方式给的优惠
        level: "", //Vip等级
        extra_coin: "", //税率
        extra_coin_num: "", //vip送的优惠
        coupon_give_coin: "", //优惠券送的优惠
        coupon_title: "", //优惠券名称
        coupon_type: "", //优惠券类型
        product_img: "", //产品图片
        product_info: "", //产品名称
        rebate: 0, //渠道返利
        checkNum: null, //优惠券索引
        coupon_info: {}, //优惠券信息
      },
      spareGoodsInfo: {},
      gateData: {}, //gate相关数据
      gameData: {}, //游戏点击进来的数据
      standbyName: null, //备用支付方式索引值
      choosePayItem: {}, //选择商品时备用
    };
  },
  computed: {
    rules() {
      return {
        name: [
          {
            required: true,
            message: this.$t("consume.enterAccount"),
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: this.$t("consume.enterPassword"),
            trigger: "blur",
          },
        ],
      };
    },
    options() {
      return [
        {
          value: "1",
          label: this.$t("consume.step1"),
          name: "info",
          disabled: false,
        },
        {
          value: "2",
          label: this.$t("consume.step2"),
          name: "goods",
          disabled: false,
        },
        {
          value: "3",
          label: this.$t("consume.step3"),
          name: "pay",
          disabled: true,
        },
        {
          value: "4",
          label: this.$t("consume.step4"),
          name: "goodsInfo",
          disabled: true,
        },
      ];
    },
    isNotice() {
      let isNotice = localStorage.getItem("isNotice");
      let game_id = Number(JSON.parse(localStorage.getItem("account")).game_id);
      return !isNotice && [6, 7].includes(game_id);
    },
  },
  watch: {
    //判断是否第一次进入
    showServe(newVal) {
      if (newVal && this.isNotice) {
        this.handleNotice();
      }
    },
  },
  created() {},
  mounted() {
    this.loading();
    this.handleScroll();
    let token = JSON.parse(localStorage.getItem("token"));
    let serve = JSON.parse(localStorage.getItem("service"));
    // if (account) {
    //   this.selectServe = true;
    // }
    if (token) {
      this.getServerInfoData();
      this.getGateData();
    }
    if (serve) {
      this.showServe = true;
      this.$store.state.showServe = true;
      this.showRolesInfo = serve;
      this.getProductInfoData(serve);
      if (this.isNotice) {
        this.handleNotice();
      }
    }
    //充gate卡后更新页面
    this.$bus.$on("updateProduct", (res) => {
      if (res && this.choosePayItem && this.showPay) {
        this.getProductDetail(this.choosePayItem);
      }
    });
  },
  methods: {
    //进入页面时loading
    loading() {
      this.showLoading = false;
      setTimeout(() => {
        this.showLoading = false;
      }, 2000);
    },
    // 点击跳转
    scrollTo(item) {
      if (item.disabled) {
        return;
      }
      this.$nextTick(() => {
        let scrollTo = this.$refs[item.name];
        scrollTo.scrollIntoView({ block: "start" });
      });
    },
    // 监听页面滚动事件
    handleScroll() {
      window.addEventListener("scroll", this._.debounce(this.scrolling, 50));
    },
    scrolling() {
      let win = window.pageYOffset + 150;
      // const info = this.$refs.info.offsetTop;
      if (!this.$refs.goods) {
        return;
      }
      const goods = this.$refs.goods.offsetTop;
      let pay, goodsInfo;
      if (this.showPay) {
        pay = this.$refs.pay.offsetTop;
      }
      if (this.showGoodsInfo) {
        goodsInfo = this.$refs.goodsInfo.offsetTop;
      }
      if (win < goods) {
        this.value = "1";
      } else if (win > goods && win < pay) {
        this.value = "2";
      } else if (win > pay && win < goodsInfo) {
        this.value = "3";
      } else if (win > goodsInfo) {
        this.value = "4";
      } else {
        this.value = "2";
      }
    },
    //打开礼包码弹窗
    openGiftCodeDialog() {
      if (this.goodsInfo.name === "Coin") {
        return;
      }
      this.$refs.giftCode.dialogFormVisible = true;
    },
    //打开预览弹框
    openPreviewDialog(item) {
      this.$refs.preview.drawer = true;
      let locale = localStorage.getItem("locale");
      let product_info = JSON.parse(item.product_info);
      let product_name = JSON.parse(item.product_name);
      item.locale = product_info[locale];
      item.locale_name = product_name[locale];
      this.$refs.preview.data = item;
    },
    //打开优惠券弹框
    openCouponDialog() {
      this.$refs.showCoupon.checkNum = this.goodsInfo.checkNum;
      if (this.goodsInfo.checkNum !== null) {
        this.$refs.showCoupon.productDetail.data.coupon[
          this.goodsInfo.checkNum
        ].check = true;
      } else {
        this.$refs.showCoupon.productDetail.data.coupon.forEach((item) => {
          item.check = false;
        });
      }
      this.$refs.showCoupon.dialogFormVisible = true;
      this.$handlePadding();
    },
    //选择区服时
    handleChange(e) {
      this.form.roles = "";
      let roles = this.servers_roles.servers.filter((item) => {
        return item.id === e;
      });
      this.servers_roles.roles = roles[0].childs;
      this.showRolesInfo.serversId = roles[0].id;
      this.showRolesInfo.serversName = roles[0].value;
    },
    //确认角色
    submitRole() {
      this.showLoading = false;
      let roles = this.servers_roles.roles.filter((item) => {
        return item.id === this.form.roles;
      });
      this.showRolesInfo.id = roles[0].id;
      this.showRolesInfo.r_id = roles[0].r_id;
      this.showRolesInfo.roleName = roles[0].value;
      // this.showRolesInfo.country = this.form.country;
      this.showServe = true;
      localStorage.setItem("service", JSON.stringify(this.showRolesInfo));
      this.getGateData();
      this.getProductInfoData(this.showRolesInfo);
    },
    //移除区服角色
    removeServe() {
      this.showLoading = false;
      localStorage.removeItem("service");
      this.showServe = false;
      this.$store.state.showServe = false;
      this.showPay = false; //展示支付方式
      this.showCoupon = false; //展示优惠券
      this.showGoodsInfo = false; //展示商品信息
      this.ruleForm = {
        name: "",
        password: "",
        type: "1",
      };
      this.form = {
        servers: "",
        roles: "",
      };
      setTimeout(() => {
        this.showLoading = false;
      }, 2000);
    },
    //打开支付额外金额获取流水弹窗
    openMoreDialog(goodsInfo) {
      this.$refs.more.dialogFormVisible = true;
      this.$refs.more.goodsInfo = goodsInfo;
    },
    //移除所有登入状态
    removeStatus() {
      this.showLoading = false;
      // localStorage.removeItem("account");
      localStorage.removeItem("token");
      // localStorage.removeItem("service");
      this.selectServe = false;
      this.showServe = false;
      this.showPay = false; //展示支付方式
      this.showCoupon = false; //展示优惠券
      this.showGoodsInfo = false; //展示商品信息
      this.ruleForm = {
        name: "",
        password: "",
        type: "1",
      };
      this.form = {
        servers: "",
        roles: "",
      };
      setTimeout(() => {
        this.showLoading = false;
      }, 2000);
    },
    //跳转页面
    toPage() {
      this.$router.push("/");
    },
    //选择商品
    choosePay(item) {
      //重构支付方式数据
      this.showLoading = false;
      this.free_chance = item.free_chance;
      // localStorage.setItem("product", JSON.stringify(item));
      this.payCountry = localStorage.getItem("payCountry")
        ? localStorage.getItem("payCountry")
        : this.payCountry;
      this.payCountry2 = this.payCountry;
      this.choosePayItem = item;
      this.checkList = [];
      this.getProductDetail(item);
    },
    // 选择优惠券
    chooseCoupon(item, index, i) {
      if (i.title === "Cổng khác") {
        this.checkList = [];
      }

      //如果gate值不够提示余额不足
      let coin =
        Number(this.$store.state.gameData.coin) +
        Number(this.$store.state.gameData.bind_coin);
      let MCoin = Number(this.$store.state.gameData.m_coin);
      const arr = ["Coin", "MoMo", "BankQR", "ATMCARD", "VISA"];
      if (item.name === "Coin" && coin < i.need_pay) {
        // showMessage("Số dư không đủ", "warning");
        if (!arr.includes(this.couponName) && this.couponName) {
          this.activeNames = ["Cổng khác"];
        } else {
          this.checkList[0] = this.couponName;
        }
        this.$message.warning(this.$t("consume.insufficientBalance"));
        return;
      }
      if (item.name === "MCoin" && MCoin < i.need_pay) {
        // showMessage("Số dư không đủ", "warning");
        if (!arr.includes(this.couponName) && this.couponName) {
          this.activeNames = ["Cổng khác"];
        } else {
          this.checkList[0] = this.couponName;
        }
        this.$message.warning(this.$t("consume.insufficientBalance"));
        return;
      }
      if (item.disabled) {
        if (!arr.includes(this.couponName) && this.couponName) {
          this.activeNames = ["Cổng khác"];
        } else {
          this.checkList[0] = this.couponName;
        }
        if (item.maintain) {
          this.$message.warning(this.$t("consume.maintain"));
        } else {
          this.$message.warning(
            this.$t("consume.MinDeposit", { money: item.min })
          );
        }

        // showMessage(`Mức nạp thấp nhất ${item.min}`, "warning");
        return;
      }
      this.showGoodsInfo = false;
      this.options[3].disabled = true;

      const { channel, pay_type } = item;
      this.payData.channel = channel;
      this.payData.pay_type = pay_type;
      // 每次选择支付方式前清空一遍数据
      this.goodsInfo = {
        name: "", //支付方式名称
        order_amt: "", //价格
        order_amt_MCoin: "", //Mcoin
        coupon_discount_amount: "", //优惠券满减
        coupon_reduce_amt: "", //扣完优惠券后的价格
        order_amt_platform: "", //coin价格
        total_give: "", //所有优惠加起来的数目
        give_coin: "", //支付方式给的优惠
        level: "", //Vip等级
        extra_coin: "", //税率
        extra_coin_num: "", //vip送的优惠
        coupon_give_coin: "", //优惠券送的优惠
        coupon_title: "", //优惠券名称
        coupon_type: "", //优惠券类型
        product_img: "", //产品图片
        product_info: "", //产品名称
        rebate: "", //渠道返利
        need_pay: "", //需要支付的方式
        checkNum: null, //优惠券索引
        coupon_info: {}, //优惠券信息
      };
      //填充数据
      const {
        order_amt,
        order_amt_country,
        order_amt_platform,
        price_code_country,
        level,
        extra_coin,
        product_img,
        product_info,
      } = this.productDetail.data;
      this.goodsInfo.name = item.name;
      this.goodsInfo.give_coin = item.give_coin || "";
      this.goodsInfo.order_amt_MCoin = order_amt; //网充限制档位修改
      this.goodsInfo.order_amt = order_amt_country;
      this.goodsInfo.price_code_country = price_code_country;
      this.goodsInfo.order_amt_platform = order_amt_platform;
      this.goodsInfo.level = level;
      this.goodsInfo.extra_coin = extra_coin;
      // this.goodsInfo.extra_coin_num = (parseInt(((extra_coin / 100) * order_amt)*100)/100);
      this.goodsInfo.extra_coin_num = extra_coin;
      this.goodsInfo.product_img = product_img;
      this.goodsInfo.product_info = product_info;
      this.goodsInfo.total_give = Number(
        Number(this.goodsInfo.give_coin) + Number(this.goodsInfo.extra_coin_num)
      ).toPrecision(2);

      this.spareGoodsInfo = JSON.parse(JSON.stringify(this.goodsInfo));
      this.goodsInfo.rebate = item.rebate;
      this.goodsInfo.need_pay = i.need_pay;
      //逻辑方面
      //选中支付方式
      if (this.couponName == item.name && this.couponActive == index) {
        this.couponName = null;
        this.couponActive = null;
        this.showCoupon = false;
        this.showGoodsInfo = false;
        this.options[3].disabled = true;
      } else {
        this.couponName = item.name;
        this.standbyName = item.name;
        this.$refs.showCoupon.couponName = item.name;
        this.couponActive = index;
      }
      this.useCouponNum = 0;
      this.productDetail.data.coupon.forEach((item) => {
        if (
          !(
            (!item.channel_type_arr.includes(this.couponName) &&
              item.title !== "Coin" &&
              item.title !== "MCoin") ||
            item.limit_time > 0 ||
            (item.usage_num_daily > 0 && item.usage_num_daily_count <= 0)
          )
        ) {
          this.useCouponNum++;
        }
      });
    },

    chooseCouponNext() {
      //如果gate值不够提示余额不足
      // let coin =
      //   Number(this.$store.state.gameData.coin) +
      //   Number(this.$store.state.gameData.bind_coin);
      // if (this.goodsInfo.name === "Coin" && coin < this.goodsInfo.need_pay) {
      //   this.$message.warning("Số dư không đủ");
      //   return;
      // }
      // if (this.goodsInfo.disabled) {
      //   this.$message.warning(` Mức nạp thấp nhất ${this.goodsInfo.min}`);
      //   return;
      // }
      // if (this.couponName == null && this.couponActive == null) {
      //   this.$message.warning(`hãy chọn lại phương thức thanh toán khác`);
      //   return;
      // }
      //显示方面
      if (this.checkNum !== null) {
        this.productDetail.data.coupon[this.checkNum].check = false;
        this.checkNum = null;
      }
      this.showCoupon = true;
      this.showGoodsInfo = true;
      this.options[3].disabled = false;
      this.$nextTick(() => {
        let goodsInfo = this.$refs.goodsInfo;
        goodsInfo.scrollIntoView({ block: "start" });
      });
    },
    //获取区服角色接口
    getServerInfoData() {
      let params = {};
      this.$http.get("/consume/serverInfo", { params }).then((res) => {
        if (res.data.code === 200) {
          this.servers_roles.servers = res.data.data.servers_roles;
          this.$store.state.ServeInfo = res.data.data.servers_roles;
          let service = JSON.parse(localStorage.getItem("service"));
          let trueService = false;
          if (service) {
            this.servers_roles.servers.forEach((item) => {
              item.childs &&
                item.childs.forEach((i) => {
                  if (
                    i.r_id === service.r_id &&
                    item.id === service.serversId
                  ) {
                    trueService = true;
                  }
                });
            });
            if (!trueService) {
              //如果匹配不到任何区服和r_id就取消
              this.removeServe();
            }
          } else {
            this.removeServe();
          }
          this.$store.state.selectServe = true;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    //获取商品信息
    getProductInfoData(serve) {
      this.payData.cp_roleid = serve.r_id + "," + serve.id;
      let params = {
        cp_roleid: this.payData.cp_roleid,
      };
      this.$http.get("/consume/productDetail", { params }).then((res) => {
        if (res.data.code === 200) {
          this.productInfoData = res.data.data;
          //默认显示type = 1的数据
          this.productInfoData.forEach((item) => {
            if (item.type === "1") {
              this.productInfo1 = item.product;
            } else {
              this.productInfo2 = item.product;
            }
          });
          this.productInfo = this.productInfo1;
          // this.productInfo = this.productInfoData[this.selectedButton].product;
          this.$store.state.showServe = true;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    filterCheck(item, index) {
      //切换type = 2
      this.selectedButton = index;
      this.productInfo =
        this.selectedButton === 0 ? this.productInfo1 : this.productInfo2;
      // this.productInfo = this.productInfoData[this.selectedButton]
      //   ? this.productInfoData[this.selectedButton].product
      //   : [];
      this.showPay = false;
      this.options[2].disabled = true;
      this.activeNames = []; //关闭
      this.couponActive = null;
      this.showCoupon = false;
      this.showGoodsInfo = false;
      this.options[3].disabled = true;
    },

    //获取gate信息
    getGateData() {
      let params = {};
      this.$http.get("/center/detail", { params }).then((res) => {
        if (res.data.code === 200) {
          this.$store.state.gameData = res.data.data;
          this.$store.state.lang = localStorage.getItem("locale");
          this.gateData = this.$store.state.gameData;
          this.gameData = this.$store.state.gameData;
          const matchedCountries = this.country.filter((item) =>
            res.data.data.open_country.includes(item.value)
          );
          this.newCountry =
            res.data.data.open_country.length > 0
              ? matchedCountries
              : this.country;
          let country = localStorage.getItem("payCountry");
          // 判断是否有元素的 value 包含 country
          const contains = this.newCountry.some((item) =>
            item.value.includes(country)
          );
          localStorage.setItem(
            "payCountry",
            contains ? country : this.newCountry[0].value
          );
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    changePayCountry(e) {
      this.payCountry = e;
      localStorage.setItem("payCountry", this.payCountry);
      // this.checkList = [];
      this.getProductDetail(this.choosePayItem);
    },
    //获取商品详细信息
    getProductDetail(info) {
      this.$store.state.product = info; //将选取的数据存储到vuex里去
      this.payData.product_id = info.product_id;
      let params = {
        product_id: info.product_id,
        country: this.payCountry,
      };
      this.checkList = [];
      this.$http.get("/consume/detail", { params }).then((res) => {
        if (res.data.code === 200) {
          this.checkList = [];
          this.showPay = true;
          this.options[2].disabled = false;
          this.activeNames = []; //关闭
          this.couponActive = null;
          this.showCoupon = false;
          this.showGoodsInfo = false;
          this.options[3].disabled = true;
          this.$nextTick(() => {
            let pay = this.$refs.pay;
            pay.scrollIntoView({ block: "start" });
          });
          this.productDetail = res.data;
          this.$refs.showCoupon.productDetail = res.data;
          const data = this.productDetail.data;
          let coin = [
            {
              title: "Coin",
              need_pay: info.need_pay_platform,
              atm: [
                {
                  name: "Coin",
                  coin: data.coin,
                },
              ],
            },
          ];
          if (this.payCountry === "VN") {
            coin.push({
              title: "MCoin",
              need_pay: data.order_amt,
              atm: [
                {
                  name: "MCoin",
                  coin: data.m_coin,
                  pay_type: "MCoin",
                },
              ],
            });
          }

          let recommend_atm = [];
          let inf = info;
          data.recommend_atm.forEach((item) => {
            let showMoney = `${data.order_amt_country} ${data.price_code_country}`;
            let info = {
              // need_pay: inf.need_pay_country,
              need_pay: data.order_amt_country,
              showMoney: showMoney,
              hot: item.hot,
              title: item.name,
              atm: [item],
            };
            recommend_atm.push(info);
          });
          // let atm = {
          //   title: "Cổng khác",
          //   atm: data.atm,
          //   need_pay: inf.need_pay,
          // };
          let newAtm = [];
          newAtm.push(...coin, ...recommend_atm);
          newAtm.forEach((item) => {
            if (item.title === "Coin") {
              let coin =
                Number(this.$store.state.gameData.coin) +
                Number(this.$store.state.gameData.bind_coin);
              item.atm.forEach((i) => {
                i.disabled = coin < item.need_pay ? true : false;
              });
              return;
            }
            let MCoin = Number(this.$store.state.gameData.m_coin);
            item.atm.forEach((i) => {
              // i.disabled = i.min > inf.need_pay_country ? true : false;
              // i.disabled = i.min > Number(item.need_pay) ? true : false;
              if (i.min) {
                i.disabled = i.min > Number(item.need_pay) ? true : false;
              } else {
                i.disabled = MCoin < Number(item.need_pay) ? true : false;
              }
              if (i.maintain) {
                i.disabled = i.maintain === 1;
              }
            });
          });
          this.productDetail.data.newAtm = newAtm;
          //优惠券操作
          data.coupon.forEach((item, index) => {
            // 该券是否有 有效时间，没有就显示可用时间
            if (item.valid_start_time) {
              // 有效时间 (券领取后)
              item.coupon_date_text = 1;
              item.date_text = `<div style="font-size: 12px;color: #83B8E7;">${item.valid_start_time}—${item.valid_end_time}</div>`;
            } else {
              // 可用时间 (未领取)
              if (item.get_start_time && item.get_end_time) {
                item.coupon_date_text = 0;
                item.date_text = `<div style="font-size: 12px;color: #83B8E7;">${item.get_start_time}—${item.get_end_time}</div>`;
              } else {
                item.date_text = ``;
              }
            }

            // 处理 满赠/满减 信息;
            switch (item.type) {
              case "1": // 满赠 （固额）
                item.give_coin_text = this.$t("consume.deliver1", {
                  money: item.order_coin,
                  gift: item.give_coin,
                });

                break;
              case "2": // 满赠 （比例）
                item.give_coin_text = this.$t("consume.deliver2", {
                  money: item.order_coin,
                  gift: item.give_coin,
                });
                break;
              case "3": // 满减 （固额）
                item.give_coin_text = this.$t("consume.deliver3", {
                  money: item.order_coin,
                  gift: item.give_coin,
                });
                break;
            }

            // 处理券的可用支付渠道信息展示
            item.channel_type_arr.forEach((child, index) => {
              if (index == 0) {
                item.pay_type_text = `${child}`;
              } else {
                item.pay_type_text = `${item.pay_type_text}, ${child}`;
              }
            });
            if (item.limit_time > 0) {
              this._couponStartTiming(item.limit_time, index, item);
            }
            // 优惠券的锁定时间（ps: 选择券下单，券会锁定10分钟不能使用）
            // if (item.limit_time > 0) {
            //   item.limit_time_text = `<div class="_coupon_box_timing">Đang Dùng <span class="_coupon_box_timing_val${index}">${this._getTimeS(
            //     item.limit_time
            //   )}</span></div>`;
            // } else {
            //   item.limit_time_text = "";
            // }
            item.check = false;
            item.channelShow = false;
          });
          // this.productDetail = res.data
          // console.log(res.data);
          //默认MOMO支付
          this.couponName = null;
          this.couponActive = null;
          this.handleCheck(recommend_atm[0].title);
        } else {
          this.$message.error(res.data.msg);
        }
        setTimeout(() => {
          this.showLoading = false;
        }, 2000);
      });
    },
    _couponStartTiming(ts, key, item) {
      let start = setInterval(function () {
        ts = ts > 0 ? ts - 1 : 0;
        item.limit_time = ts;
        // this.getTimeS(ts);
        if (ts <= 0) {
          clearInterval(start);
        }
      }, 1000);
    },
    changeActive(e) {
      let atmData = this.productDetail.data;
      atmData.newAtm.forEach((item, index) => {
        if (item.title === e) {
          this.chooseCoupon(
            atmData.newAtm[index].atm[0],
            index,
            atmData.newAtm[index]
          );
        }
      });
    },

    handleCheck(e) {
      const item = this.productDetail.data.newAtm.find(
        (row) => row.title === e
      );
      let atmData = this.productDetail.data;
      atmData.newAtm.forEach((i, index) => {
        if (i.title == e) {
          this.handleCheckBox(item);
          this.activeNames = [];
          this.changeActive(item.title);
        }
      });
    },

    handleCheckBox(e) {
      let val = e.title;
      let item = e.atm[0];
      //如果gate值不够提示余额不足
      let coin =
        Number(this.$store.state.gameData.coin) +
        Number(this.$store.state.gameData.bind_coin);
      if (item.name === "Coin" && coin < e.need_pay) {
        return;
      }
      if (item.disabled) {
        return;
      }
      if (val === this.checkList[0] && this.payCountry === this.payCountry2) {
        this.checkList = [];
      } else {
        this.checkList = [val];
      }
      // if (val !== this.checkList[0]) {
      //   this.checkList = [val];
      // }
      this.payCountry2 = this.payCountry;
    },

    //打开公告弹窗
    handleNotice() {
      localStorage.setItem("isNotice", true);
      this.$refs.notice.dialogFormVisible = true;
      this.$handlePadding()
    },

    handlePay: debounce(function () {
      const data = JSON.parse(localStorage.getItem("service"));
      this.payData.cp_sid = data.serversId;
      this.payData.cp_roleid = data.r_id + "," + data.id;
      let params = this.payData;
      params.token = localStorage.getItem("token");
      let coin =
        Number(this.$store.state.gameData.coin) +
        Number(this.$store.state.gameData.bind_coin);
      let MCoin = Number(this.$store.state.gameData.m_coin);
      if (
        this.goodsInfo.name === "Coin" &&
        this.goodsInfo.order_amt_platform > coin
      ) {
        this.$message.warning(this.$t("consume.insufficientBalance"));
        return;
      }
      if (
        this.goodsInfo.name === "MCoin" &&
        Number(this.goodsInfo.order_amt_MCoin) > MCoin
      ) {
        this.$message.warning(this.$t("consume.insufficientBalance"));
        return;
      }
      this.$http
        .post(`/consume/pay?country=${this.payCountry}`, params)
        .then((res) => {
          if (res.data.code === 200) {
            if (res.data.data.redirect_url) {
              window.location.href = res.data.data.redirect_url;
            } else {
              this.$alert(
                `<div class="alert-message">${this.$t(
                  "consume.paySuccess"
                )}</div>`,
                {
                  dangerouslyUseHTMLString: true,
                  confirmButtonText: this.$t("Info.cancel"),
                }
              );
              // this.$message.success("Thanh toán thành công");
              this.getGateData(); //重新获取一下coin金额
            }
          } else {
            this.$message.error(res.data.msg);
          }
        });
    }, 1000),
  },
  beforeDestroy() {
    this.$bus.$off("updateProduct");
  },
  destroyed() {
    window.removeEventListener("scroll", this.scrolling);
  },
};
</script>

<style scoped lang="scss">
$color-theme: rgb(17, 136, 255);
$color-theme-rgb: 17, 136, 255;
@import "./css/pc.scss";
@import "./css/phone.scss";
/deep/ .el-form-item__content {
  margin-left: 0 !important;
}
/deep/ .el-popper {
  left: 0px !important;
}
/deep/ .popper__arrow {
  opacity: 0 !important;
}
</style>
<style>
.el-message-box {
  width: auto !important;
}
.alert-message {
  font-size: 18px;
}
@media screen and (max-width: 1000px) {
  .el-message-box {
    width: 75% !important;
  }
}
@media screen and (max-width: 368px) {
  .el-message {
    min-width: 255px;
  }
}
</style>
